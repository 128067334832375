import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { gHeight, isMobile } from '../utils/getBodySizes';
import { getDomain, scrollTopIframe } from '../utils/domainUtility';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';




const Slider = () => {
    const vehicles = [
        {
            "slider_1":
            {
                "name": "Rogue",
                "car_model_id": 33,
                "car_model_year": 2024,
                "description": "APR desde 2.99%",
                "legal": "Oferta desde 2.99% APR aplica a los modelos Nissan Rogue 2024 para clientes con puntuación de crédito de 750 en adelante. Producto ofrecido por Popular Auto LLC. Sujeto a aprobación de crédito. Ciertas restricciones aplican. Otras tasas aplican a otras experiencias de crédito, según los parámetros y criterios establecidos por Popular Auto LLC. No incluye seguros, tablilla, ACAA, gastos de registro ni accesorios. Ofertas aplican al acogerse a pago directo. No se puede combinar con otras ofertas o descuentos. Popular Auto y la red de dealers Nissan no son afiliados. Para más detalles sobre las ofertas aquí publicadas e información sobre especificaciones por variante, disponibilidad de tecnologías, visite nissan.pr o los concesionarios Nissan autorizados participantes. Los precios ilustrados son los precios sugeridos por el manufacturero. MPG basado en la guía EPA de rendimiento de combustible. El rendimiento de combustible puede variar dependiendo de sus hábitos de manejo, condiciones de la carretera y otros factores. Oferta válida desde el 1 al 31 de agosto de 2024.",
                "cta1url": "https://es.nissan.pr/herramientas-de-compra/Prueba_de_manejo.html",
                "cta1label": "Coordina una prueba de manejo",
                "cta2url": "https://es.nissan.pr/herramientas-de-compra/Estimados.html",
                "cta2label": "Solicita un estimado",
                "image_desk": "/sliders/slider-Rogue-desk.png",
                "image_mob": "/sliders/slider-Rogue-mob.png"
            }
        },
        {
            "slider_2":
            {
                "name": "Versa",
                "car_model_id": 26,
                "car_model_year": 2024,
                "description": "Bono de $1,500",
                "legal": "Bono de $1,500 ($1,250 del distribuidor más $250 del concesionario) aplicable al pronto pago de todas las variantes Nissan Versa 2024. Ejemplo de bono para Versa 2024 VES-2400: MSRP de $21,155 menos $1,500 de bono, balance a financiar de $19,655. Ciertos términos, restricciones y condiciones aplican. Ciertos términos, restricciones y condiciones aplican. No incluye seguros, tablilla, ACAA, gastos de registro ni accesorios. Ofertas publicadas no pueden combinarse con otras ofertas, descuentos o entre sí. Para más detalles sobre las ofertas aquí publicadas e información sobre especificaciones por modelo, disponibilidad de tecnologías, visite nissan.pr o los dealers Nissan autorizados participantes. Los precios ilustrados son los precios sugeridos por el manufacturero. MPG basado en la guía EPA de rendimiento de combustible. El rendimiento de combustible puede variar dependiendo de sus hábitos de manejo, condiciones de la carretera y otros factores. Oferta válida del 1 al 31 de agosto de 2024.",
                "cta1url": "https://es.nissan.pr/herramientas-de-compra/Prueba_de_manejo.html",
                "cta1label": "Coordina una prueba de manejo",
                "cta2url": "https://es.nissan.pr/herramientas-de-compra/Estimados.html",
                "cta2label": "Solicita un estimado",
                "image_desk": "/sliders/Slider-Versa-desktop.jpg",
                "image_mob": "/sliders/Slider-Versa-mobile.jpg"

            }
        },
    ];

    //Cross-Origin for Scroll Top Iframe
    let domain = 'http://localhost';

    if (window.location !== window.parent.location) {
        domain = getDomain(document.referrer);
    }

    const handleModal = (name, legal) => {
        const modal = document.querySelector('.modal');
        const modalBody = modal.querySelector('.modal--content-body');
        const modalWrapper = modal.querySelector('.modal--wrapper');
        const modalTitle = modalBody.querySelector('h5');
        const modalContent = modalBody.querySelector('p');

        modalTitle.innerText = `${name}®`
        modalContent.innerText = legal

        modal.classList.add("active");

        if (!isMobile) {
            modalWrapper.style.height = gHeight() + "px";
        } else {
            modalWrapper.classList.add("mobile");
        }

        scrollTopIframe(domain);

    }

    return (
        <Swiper
            id='slider'
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
        >


            <SwiperSlide>
                <div className="slider--content-wrapper">

                    <div className="slider--content">
                        <div className="slider--content-info ">
                            <h2 className='uppercase'>{vehicles[1].slider_2.name}®</h2>
                            <p className='price'>{vehicles[1].slider_2.description} <button onClick={() => handleModal(vehicles[1].slider_2.name, vehicles[1].slider_2.legal)}>[*]</button></p>

                        </div>
                        <div className="slider--content-action">

                            <a href="javascript:void(0);" className="cta-button-offer btn group " onClick={() => handleModal(vehicles[1].slider_2.name, vehicles[1].slider_2.legal)} >Ver oferta<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6"></path></svg></a>

                            <a href={vehicles[1].slider_2.cta2url} target="_parent" className="cta-button-offer btn group" data-car-model-id="26" data-car-model-year="2024" data-car-model-name="Versa">{vehicles[1].slider_2.cta2label}<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6"></path></svg></a>
                        </div>
                    </div>

                    <img
                        className="opacity-0 md:opacity-100"
                        src={vehicles[1].slider_2.image_desk}
                        alt={vehicles[1].slider_2.name} />
                    <img
                        className=' opacity-100 md:opacity-0'
                        src={vehicles[1].slider_2.image_mob}
                        alt={vehicles[1].slider_2.name} />
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="slider--content-wrapper">

                    <div className="slider--content">
                        <div className="slider--content-info ">
                            <h2 className='uppercase'>{vehicles[0].slider_1.name}®</h2>
                            <p className='price'>{vehicles[0].slider_1.description} <button onClick={() => handleModal(vehicles[0].slider_1.name, vehicles[0].slider_1.legal)}>[*]</button></p>

                        </div>
                        <div className="slider--content-action">

                            <a href="javascript:void(0);" className="cta-button-offer btn group " onClick={() => handleModal(vehicles[0].slider_1.name, vehicles[0].slider_1.legal)} >Ver oferta<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6"></path></svg></a>

                            <a href={vehicles[0].slider_1.cta2url} target="_parent" className="cta-button-offer btn group" data-car-model-id="26" data-car-model-year="2024" data-car-model-name="Versa">{vehicles[0].slider_1.cta2label}<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6"></path></svg></a>
                        </div>
                    </div>

                    <img
                        className="opacity-0 md:opacity-100"
                        src={vehicles[0].slider_1.image_desk}
                        alt={vehicles[0].slider_1.name} />
                    <img
                        className=' opacity-100 md:opacity-0'
                        src={vehicles[0].slider_1.image_mob}
                        alt={vehicles[0].slider_1.name} />
                </div>
            </SwiperSlide>
        </Swiper>
    );
};

export default Slider
