import React from 'react'
import Button from './Button'
import formatCurrency from '../utils/formatCurrency';
import { gHeight, isMobile } from '../utils/getBodySizes';
import { getDomain, scrollTopIframe } from '../utils/domainUtility';

const Card = ({ vehicle }) => {

    //Cross-Origin for Scroll Top Iframe
    let domain = 'http://localhost';

    if (window.location !== window.parent.location) {
        domain = getDomain(document.referrer);
    }

    const handleModal = () => {
        const modal = document.querySelector('.modal');
        const modalBody = modal.querySelector('.modal--content-body');
        const modalWrapper = modal.querySelector('.modal--wrapper');
        const modalTitle = modalBody.querySelector('h5');
        const modalContent = modalBody.querySelector('p');

        modalTitle.innerText = `${vehicle.car_model.name}®`
        modalContent.innerText = vehicle.legal

        modal.classList.add("active");

        if (!isMobile) {
            modalWrapper.style.height = gHeight() + "px";
        } else {
            modalWrapper.classList.add("mobile");
        }

        scrollTopIframe(domain);

    }

    return (
        <div className="card active-card" data-category-id={vehicle.category} data-vehicle-id={vehicle.car_model.id}>
            <div className="card--title">
                <h3>{vehicle.car_model.name}<span>®</span></h3>
            </div>
            <div className="card--image">
                <img src={vehicle.car_model.image} width={400} height={300} alt={vehicle.car_model.name} />
            </div>
            <div className="card--content">
                {vehicle.price !== 0 &&
                    <div className="card--content-info">
                        <div className="price">
                            <span>$</span>
                            {formatCurrency(vehicle.price)}
                            <button onClick={handleModal}>[*]</button>
                        </div>
                        <strong>de BONO</strong>
                    </div>
                }
                {vehicle.apr !== '' &&
                    <div className="card--content-info relative">
                        <div className="text-xs absolute -top-3">Desde</div>
                        <div className="price relative">
                            {vehicle.apr}
                            <span>%</span>
                            <button onClick={handleModal}>[*]</button>
                        </div>
                        <strong>APR</strong>
                    </div>
                }
            </div>
            <div className="card--action">
                {vehicle.call_to_actions.map((cta, index) =>
                    <Button
                        key={index}
                        label={cta.label}
                        urlTarget={cta.url}
                        CarModelID={vehicle.car_model.id}
                        CarModelYear={vehicle.car_model.year}
                        CarModelName={vehicle.car_model.name}
                        CTAHandle={handleModal}
                    />
                )}
            </div>
        </div>
    )
}

export default Card